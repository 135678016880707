import React from "react";
import "../style/font-style.css";
import { Box, Grid, Hidden } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";

import Img from "gatsby-image";
import "../css/base.css";

import BoxTypo from "../components/BoxTypo";
import Faq from "../components/faq";

export default function Home() {
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true,
      anchorPlacement: "top-top",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      allMicrocmsNews(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            date
            content
            title
            newsId
          }
        }
      }
      checklist: file(relativePath: { eq: "cheklist.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_checklist: file(relativePath: { eq: "sp_cheklist.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ShonaiTravSiteImg: file(relativePath: { eq: "shonaiTravSiteImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SuidenImg: file(relativePath: { eq: "suidenImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box width="100%" bgcolor="#fff">
      <Head />
      <Hidden smDown>
        <Box width="100%" m={"auto"} maxWidth="1100px">
          <Img fluid={data.checklist.childImageSharp.fluid} />
          <Link className="bottom" to="/" width="150px">
            <BoxTypo
              Tsukubrdgothic
              fs={["18px"]}
              ls={["2px"]}
              lh={["200%"]}
              py={[5]}
              textAlign="center"
            >
              ←TOPへ
            </BoxTypo>
          </Link>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box width="100%" m={"auto"} maxWidth="1100px">
          <Img fluid={data.sp_checklist.childImageSharp.fluid} />
          <Link className="bottom" to="/" width="150px">
            <BoxTypo
              Tsukubrdgothic
              fs={["18px"]}
              ls={["2px"]}
              lh={["200%"]}
              pt={[5]}
              pb={5}
              textAlign="center"
            >
              ←TOPへ
            </BoxTypo>
          </Link>
        </Box>
      </Hidden>
    </Box>
  );
}

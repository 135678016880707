import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import Fotter from "../components/footer";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import BoxTypo from "../components/BoxTypo";

const nlRegex = /(\r\n|\r|\n)/g;
const nl2br = (str) =>
  str.split(nlRegex).map((line, i) =>
    line.match(nlRegex) ? (
      <>
        <br key={i} />
      </>
    ) : (
      line
    )
  );

const FAQ = () => {
  const popularQuestion = [
    {
      question: "①釣りバス",
      answer: `※運行は終了致しました。`,
      option: [],
    },
    {
      question: "②チョクノリ",
      answer: `チョクノリをご利用可能です。詳細については下記URLからご確認下さい。`,
      option: [
        {
          title: "チョクノリ　詳細URL",
          link:
            "https://www.ana.co.jp/ja/jp/book-plan/airport-access/shonai-airport.html",
          linkTitle:
            "https://www.ana.co.jp/ja/jp/book-plan/airport-access/shonai-airport.html",
        },
      ],
    },
    {
      question: "③釣りカー",
      answer: `釣りカーを利用して移動することが可能です。詳細については下記URLからご確認下さい。`,
      option: [
        {
          title: "釣りカー 詳細URL",
          link: "https://yawatajidosha.net/yamagatalife/yamagatalife-2655/",
          linkTitle:
            "https://yawatajidosha.net/yamagatalife/yamagatalife-2655/",
        },
      ],
    },
  ];

  return (
    <>
      <Box textAlign="center" width="100%" pt="128px" pb="128px">
        <BoxTypo
          fs={["18px", "22px", "26px"]}
          ls={["2px"]}
          lh={["200%"]}
          Tsukubrdgothic
        >
          釣り場へのアクセス
        </BoxTypo>
        <BoxTypo
          fs={["12px", "12px", "16px"]}
          mt={"64px"}
          mb="clamp(32px,10%,64px)"
          Tsukubrdgothic
        >
          　様々な方法で釣り場へアクセスすることが可能です。
        </BoxTypo>
        {popularQuestion.map((c, i) => {
          return (
            <Section
              question={c.question}
              answer={c.answer}
              index={i}
              addLink={c.option && c.option}
            />
          );
        })}
      </Box>
    </>
  );
};

export default FAQ;

const Section = ({ question, answer, index, addLink }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Box width="100%" id={index}>
      <Box width="90%" maxWidth="1000px" textAlign="left" m={"0 auto"}>
        <Button
          onClick={() => setOpen((t) => !t)}
          style={{
            width: "100%",
            margin: "0",
            padding: "0",
            borderBottom: "1px solid #ddd",
          }}
        >
          <Box
            display="flex"
            width="95%"
            m={"auto 0"}
            justifyContent="space-between"
            textAlign="left"
            alignItems="center"
          >
            <BoxTypo
              mt="3%"
              fs={["12px", "12px", "18px"]}
              lh="180%"
              mb="3%"
              mr="5%"
              Tsukubrdgothic
            >
              {question}
            </BoxTypo>
            {isOpen ? (
              <ExpandLess style={{ margin: "12px 0" }} />
            ) : (
              <ExpandMore style={{ margin: "12px 0" }} />
            )}
          </Box>
        </Button>
        {isOpen ? (
          <Box m={"auto"} width="95%">
            <Box width="90%" maxWidth="800px" textAlign="justify">
              <BoxTypo
                mt="10%"
                lh="200%"
                fs={["12px", "12px", "16px"]}
                mb="10%"
                Tsukubrdgothic
              >
                {nl2br(answer)}
              </BoxTypo>
              {addLink &&
                addLink.map((c, i) => {
                  return (
                    <>
                      <BoxTypo
                        mt="0"
                        lh="200%"
                        fs={["12px", "12px", "16px"]}
                        mb="3%"
                        Tsukubrdgothic
                      >
                        {c.title}
                      </BoxTypo>
                      <a
                        href={c.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <BoxTypo
                          lh="200%"
                          fs={["12px", "12px", "16px"]}
                          mb="5%"
                          color="skyblue"
                          Tsukubrdgothic
                        >
                          {c.linkTitle ? c.linkTitle : c.link}
                        </BoxTypo>
                      </a>
                    </>
                  );
                })}
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
